import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Login from "./Login";
import ForgotPass from "./ForgotPass";
import ResetPass from "./ResetPass"
import AboutUs from "./AboutUs";
// import Message from "./Message";
// import Redeem from "./Redeem";
// import MyAccount from "./MyAccount";
// import Settings from "./Settings";
// import Help from "./Help";
import useCurrentUser from "./useCurrentUser";
import ErrorPage from "./ErrorPage";
import ChangePass from "./ChangePass"
import Profile1 from "./Profile1";
import Profile from "./Profile";
import DashboardMob from "./DashboardMob";
import Giveaway from "./Giveaway";
import Refer from "./Refer";
import DashboardSidebar from "./DashboardComp";
import { ActiveProvider } from './ActiveContext';
import { useEffect } from "react";
import { useActiveContext } from './ActiveContext';
import Welcome from "./Welcome";
import TermsAndConditions from "./TermsAndConditions";




export default function Router(){
  const [currentUser, setCurrentUser] = useCurrentUser();
  const { active,setActive } = useActiveContext();
  useEffect(()=>{
    let activeComp = window.location.href.split("/");
    activeComp = activeComp.slice(-1)[0]
    // console.log(activeComp)
    setActive(activeComp)

    // alert(activeComp)
    window.addEventListener('popstate', () => {
      let activeComp = window.location.href.split("/")[3];
      setActive(activeComp)
    });
  },[])

    return (
 

        <BrowserRouter>
          <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/welcome" element={ <Welcome /> } />
            <Route path="/giveAway" element={!currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            {/* <Route path="/refer" element={!currentUser?<ErrorPage text="You are not logged in"/>:<Refer /> } /> */}
            {/* <Route path="/profile1" element={ <Profile1 /> } /> */}
            <Route path="/profile" element={!currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            <Route path="/profileNew" element={!currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            <Route path="/verify/*" element={<ErrorPage text="email verification"/>} />
            {/* <Route path="/login/:referralCode" element={ <Login /> } /> */}
            <Route path="/login" element={ <Login /> } />
            <Route path="/login/:referralCode" element={ <Login /> } />
            <Route path="/signup/:referralCode" element={ <Login /> } />
            <Route path="/signup" element={ <Login /> } />
            <Route path="/forgotpassword/*" element={ <ForgotPass /> } />
            <Route path="/resetpassword/*" element={ <ResetPass /> } />
            <Route path="/changePass" element={ <ChangePass/> } />
            <Route path="/about" element={ <AboutUs /> } />
            {/* <Route path="/dashboard" element={<Dashboard /> } /> */}
            <Route path="/dashboard" element={ !currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            <Route path="/refer" element={ !currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            <Route path="/dashboardMob" element={ !currentUser?<ErrorPage text="You are not logged in"/>:<DashboardMob /> } />
            <Route path="/message" element={!currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            {/* <Route path="/message" element={<Message /> } /> */}
            <Route path="/redeem/*" element={ !currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            <Route path="/myAccount" element={ !currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            <Route path="/settings" element={ !currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
            <Route path="/help" element={ !currentUser?<ErrorPage text="You are not logged in"/>:<Dashboard /> } />
           
            <Route path="/termsAndConditions" element={ <TermsAndConditions/> } />
            <Route path="*" element={ <Home/> } />
          </Routes>
        </BrowserRouter>

    
      );
}


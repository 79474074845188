import React, { useState } from 'react'
import panLogo from "../images/panelLogo.png";
import myApi from './MyApi';
import { Navigate, useNavigate } from 'react-router-dom';
import DashboardTop from './DashboardTop';
import DashboardHome from './DashboardHome';
import Message from './Message';
import Redeem from './Redeem';
import MyAccount from './MyAccount';
import Settings from './Settings';
import Help from './Help';
import Profile from './Profile'
import TrendingSurvey from './TrendingSurvey';
import Giveaway from './Giveaway';
import Refer from './Refer';
import RewardSideBar from './RewardSideBar';
import { useActiveContext } from './ActiveContext';
import useIsMobile from './useIsMobile';
import RewardCenter from './RewardCenter';
import Bid from './Bid';
import Donation from './Donation';
import ProductStore from './ProductStore';
import Ewallet from './Ewallet';
import Orders from './Orders';
import ProfileNew from './ProfileNew';


export default function DashboardComp(props) {
  const navigate = useNavigate();
  const {sideBarDis,setSideBarDis,darkTheme} = useActiveContext();
  const isMobile = useIsMobile()
  return (
    <div className="mainCont dfr" style={{background:darkTheme&&'var(--darkBg)'}} onClick={()=>isMobile && sideBarDis && setSideBarDis(false)}>
    {props.active==="dashboard"&&<><DashboardHome/><TrendingSurvey/></>}
    {props.active==="message"&&<><Message/><TrendingSurvey/></>}
    {props.active==="redeem"&&<><Redeem/><RewardSideBar/></>}
    {props.active==="rewardCenter"&&<><RewardCenter/><RewardSideBar/></>}
    {props.active==="bid"&&<><Bid/><RewardSideBar/></>}
    {props.active==="donation"&&<><Donation/><RewardSideBar/></>}
    {props.active==="productStore"&&<><ProductStore/><RewardSideBar/></>}
    {props.active==="eWallet"&&<><Ewallet/><RewardSideBar/></>}
    {props.active==="orders"&&<><Orders/><RewardSideBar/></>}
    {props.active==="myAccount"&&<><MyAccount/><TrendingSurvey/></>}
    {props.active==="settings"&&<><Settings/><TrendingSurvey/></>}
    {props.active==="help"&&<><Help/><TrendingSurvey/></>}
    {props.active==="profileNew"&&<Profile/>}
    {props.active==="profile"&&<ProfileNew />}
    {props.active==="giveaway"&&<><Giveaway/><TrendingSurvey/></>}
    {props.active==="refer"&&<><Refer/><TrendingSurvey/></>}
    {/* <Message/> */}
    {/* <Redeem/> */}
    {/* <MyAccount/> */}
    {/* <Settings/> */}
    {/* <Help/> */}
  </div>
  )
}


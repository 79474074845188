import React, {useRef, useEffect, useState } from "react";
import DashboardSidebar from "./DashboardComp";
import DashboardTop from "./DashboardTop";
import useCurrentUser from "./useCurrentUser";
import axios from "axios";
import myApi from "./MyApi";
import PanelQuestions from "./panelQuestions.json";
import { getData, showErrorCard } from "../App";
import useIsMobile from "./useIsMobile";
import renewAccessToken from "./renewAccessToken";
import { render } from "@testing-library/react";
import { Navigate, useNavigate } from "react-router-dom";
import userMale from "../images/userMale.png";
import userFemale from "../images/userFemale.png";
import { useActiveContext } from "./ActiveContext";
import Loader from "./Loader";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
// import TrendingSurvey from "./TrendingSurvey";

function Profile() {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(null);

  const [points, setPoints] = useState({
    s1: 0,
    s2: 0,
    s3: 0,
    s4: 0,
    s5: 0,
    s6: 0,
  });

  const [questionList, setQuestionList] = useState(PanelQuestions);
  const [currQuestionList, setCurrQuestionList] = useState([]);
  const quePerPage = 3;
  // let slideNum = Math.round(PanelQuestions.length / quePerPage) + 1;
  const slideNum = 8;
  const totalSlides = Array.from({ length: slideNum });
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [personInfo, setPersonInfo] = useState(false);
  const exclusiveValues = ["96", "99", "exclusive"];
  const isMobile = useIsMobile();
  const category = [" ", " ", " 🏚️", " 🏥", " 🏅", " 😍", " ✈️", " 📺"];
  const categoryIcon = ["fa-regular fa-user", "fa-regular fa-user"];
  const [currSlide, setCurrSlideNum] = useState(0);
  const [questionState, setQuestionState] = useState([]);
  const [selectedFile, setSelectedImage] = useState();
  const [page, setPage] = useState(1);
  const [pageHead, setPageHead] = useState("Test");
  const [bio, setBio] = useState("Sample Bio");
  const { active, setActive, darkTheme } = useActiveContext();
  const [uploadDis, setUploadDis] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dQues, setDques] = useState(false);
  const [pageHeadList,setPageHeadList] = useState([
    // 'About you',
    // 'More about you',
    // 'About your household',
    // 'Medical Status',
    // 'Your Interest',
    // 'Your favourites',
    // 'Travel',
    // 'Appliances'
  ]);

  const getPageHeader = async ()=>{
    const  pageHeaderResp = await getData('/panel/India/pageHeader/en');
    let data = pageHeaderResp.data;
    let newArr = [];
    data.forEach((elem)=>{
      newArr.push(elem.header);
    })
    setPageHeadList(newArr)
  }
  useEffect(() => {
    if (currentUser) {
      if (currentUser.result.picUrl == null) {
        let gender = currentUser.result.gender;
        if (gender == 1) setSelectedImage(userMale);
        else setSelectedImage(userFemale);
      }
    }
  }, [currentUser]);
  useEffect(() => {
    getApi(currSlide);
    getPageHeader();
  }, [currSlide]);

  const getObjectByKey = (panelData, key) => {
    for (let obj of panelData) {
      if (key in obj) {
        return obj[key];
      }
    }
    return null;
  };

  const dependentQuestionChange = (val) => {
    val == 1 ? setDques(true) : setDques(false);
  };
  const changeQuestionColor = (e) => {
    // let questionText = e.target.parentNode.parentNode.querySelector(".questionText");
    // questionText.setAttribute("style","background: rgb(63, 173, 138); color: white;")
  };
  const getOpenEndValue = async (qLabel) => {
    const newData = await getNewUserData();
    const result = getObjectByKey(newData.result.panelData, qLabel);
    // console.log(result);
    return result.openEndText;
  };
  const changeBasicInfo = (label, type, val) => {
    if (type === "single") {
      let valueArr = [val];
      selectionChange(label, valueArr);
    }

    if (type === "numeric") {
      if (String(val).length < 6) {
        showErrorCard("Pincode should be of 6 Characters", "error");
      }
      let valueArr = [val];
      selectionChange(label, valueArr);
    }
  };

  const postData = async (url, data) => {
    try {
      const token = localStorage.getItem("accessToken");
      const userResponse = await myApi.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
  };
  const patchData = async (url, data) => {
    try {
      const token = localStorage.getItem("accessToken");
      const userResponse = await myApi.patch(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.log(err);
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
  };

  const getApi = async (currSlide) => {
    setLoading(true);
    let firstPageUrl = "panel/India/page1/en/all";
    let secondPageUrl = "panel/India/page2/en";
    let thirdPageUrl = "panel/India/page3/en/1";
    let fourthPageUrl = "panel/India/page4/en";
    let fifthPageUrl = "panel/India/page5/en";
    let sixthPageUrl = "panel/India/page6/en";
    let seventhPageUrl = "panel/India/page7/en";
    let eighthPageUrl = "panel/India/page8/en";

    let response;
    if (currSlide === 0) response = await myApi.get(firstPageUrl);
    if (currSlide === 1) response = await myApi.get(secondPageUrl);
    if (currSlide === 2) response = await myApi.get(thirdPageUrl);
    if (currSlide === 3) response = await myApi.get(fourthPageUrl);
    if (currSlide === 4) response = await myApi.get(fifthPageUrl);
    if (currSlide === 5) response = await myApi.get(sixthPageUrl);
    if (currSlide === 6) response = await myApi.get(seventhPageUrl);
    if (currSlide === 7) response = await myApi.get(eighthPageUrl);
    // console.log(response.data);
    setCurrQuestionList(response.data.Questions);
    setPageHead(response.data.pageheader);
    let userResponse;
    try {
      const token = localStorage.getItem("accessToken");
      userResponse = await myApi.get("/panel/ProfileIndia/profilePanel", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
      setLoading(false);
    }
    if (currSlide == 0) {
      // Make an authenticated API request
      setTimeout(async () => {
        if (userResponse == null) {
          window.location.reload();
          return;
        }
        let userResp = await userResponse.data.result;
        let select = document.querySelectorAll(".infoField select");
        let input = document.querySelectorAll(".infoField input");
        input[0].value = userResp.dateOfBirth;
        let pinCode = getOptionNum(
          input[1].getAttribute("qLabel"),
          userResp.panelData
        );
        input[1].value = pinCode[0];
        select.forEach((elem) => {
          // console.log(elem);
          let qLabel = elem.getAttribute("qLabel");
          // console.log(qLabel);
          let val = getOptionNum(qLabel, userResp.panelData);
          elem.value = val[0];
          setBio(userResp.bio);
          let bioPoints = userResp.bioPoints;
          points.s1 = bioPoints[0];
          points.s2 = bioPoints[1];
          points.s3 = bioPoints[2];
          points.s4 = bioPoints[3];
          points.s5 = bioPoints[4];
          points.s6 = bioPoints[5];
        });
      }, 10);
    }

    if (currSlide >= 1) {
      setTimeout(async () => {
        if (userResponse == null) {
          window.location.reload();
          return;
        }
        let userResp = await userResponse.data.result;
        let single = document.querySelectorAll(".single");

        // console.log(single.length);

        for (let i = 0; i < single.length; i++) {
          let qLabel = single[i].getAttribute("qlabel");
          // console.log(qLabel);
          let panelData = await userResp.panelData;
          let optionsArr = getOptionNum(qLabel, panelData);
          single[i].value = optionsArr[0];
        }
        const newData = await getNewUserData();
        await changeBtnText(newData.result.panelData);

        if (currSlide === 2) {
          let elem = document.querySelector(
            'select[qlabel="Child_Household_IN"]'
          );
          if (elem) {
            elem.value == 1 ? setDques(true) : setDques(false);
          }
        }
      }, 10);
    }
  };

  const changeBtnText = (panelData) => {
    let multi = document.querySelectorAll(".multi");
    for (let i = 0; i < multi.length; i++) {
      let qLabel = multi[i].getAttribute("qLabel");
      let optionsArr = getOptionNum(qLabel, panelData);
      let allMultiOptions = document.querySelectorAll(
        `.multiOptions[qLabel=${qLabel}]`
      );
      let btnText = "";
      allMultiOptions.forEach(async (elem) => {
        let value = parseInt(elem.getAttribute("value"));
        if (optionsArr.includes(value)) {
          elem.click();
          if (elem.getAttribute("type") === "open_end") {
            btnText +=
              (await getOpenEndValue(elem.getAttribute("qlabel"))) + ", ";
          } else {
            btnText += elem.innerText + ", ";
          }
          let finalText = btnText.slice(0, -2);
          multi[i].innerText = finalText;
          let finalTextLen = finalText.length;
          finalText = finalText.slice(0, 25);
          multi[i].innerText = finalText;
          if (finalTextLen > 25) multi[i].innerText = finalText + ".....";
        }
      });
    }
  };

  const getOptionNum = (qlabel, panelData) => {
    for (var i = 0; i < panelData.length; i++) {
      var obj = panelData[i];
      var objKey = Object.keys(obj)[0]; // Assuming each object contains only one key
      if (objKey === qlabel) {
        return obj[qlabel].option;
      }
    }
    return null; // If key not found
  };

 

  useEffect(() => {
    const initialStateArray = questionList?.map((question) => ({
      label: question.label,
      value: false,
      btnText: "Select Multiple Options",
    }));

    setQuestionState(initialStateArray);
    // console.log(initialStateArray);

    if (currentUser) {
      setTimeout(() => {
        if (currentUser.result.picUrl) {
          setSelectedImage(currentUser.result.picUrl);
        }
      }, 100);
    }
  }, [currentUser]);

  const [queSlide, setQueSlide] = useState(1);

  const totalPoints = Array.from({ length: 5 });

  const selectionChange = async (qLabel, value, openText) => {
    // console.log("qlabel is ", qLabel);
    // console.log("isOpenText", openText);
    let data;
    if (openText === undefined) {
      data = {
        panelData: [{ [qLabel]: { option: value } }],
      };
    } else {
      data = {
        panelData: [{ [qLabel]: { option: value, openEndText: openText } }],
      };
    }
    // console.log(data);

    try {
      const token = localStorage.getItem("accessToken");
      const url = "panel/ProfileIndia/profilePanelUpdate";
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await myApi.patch(url, data, { headers });
      // console.log("Patch request successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error occurred during patch request:", error);
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
    // console.log(data);
  };

  const changeSelectedCircle = async (s, value) => {
    await setPoints((prev) => ({
      ...prev,
      [s]: value,
    }));
    points[s] = value;

    if (
      points.s1 == 0 ||
      points.s2 == 0 ||
      points.s3 == 0 ||
      points.s4 == 0 ||
      points.s5 == 0 ||
      points.s6 == 0
    )
      return;
    let bioResp;
    try {
      const token = localStorage.getItem("accessToken");
      bioResp = await myApi.get(
        `/panel/ProfileIndia/personality?personality_1=${points.s1}&personality_2=${points.s2}&personality_3=${points.s3}&personality_4=${points.s4}&personality_5=${points.s5}&personality_6=${points.s6}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }

    // let profileIntro = document.querySelector(".profileIntro");
    // profileIntro.innerText = bioResp.data.bio;
    setBio(bioResp.data.bio);
    // console.log(bioResp.data.bio);
    patchData("/panel/ProfileIndia/profilePanelUpdate", {
      bioPoints: [
        points.s1,
        points.s2,
        points.s3,
        points.s4,
        points.s5,
        points.s6,
      ],
    });
  };

  const nextSlide = () => {
    if (queSlide === slideNum) {
      showErrorCard("You have answered all the questions", "success");
      setTimeout(() => {
        navigate("/dashboard");
        setActive("dashboard");
        // window.location.href = (window.location.href).split('/').slice(0, 3).join('/')+"/dashboard";
      }, 1000);
      return;
    }
    if (queSlide == 1) {
      const newBio = {
        panelData: [],
        bio: bio,
      };

      // console.log(newBio);
      patchData("/panel/ProfileIndia/profilePanelUpdate", newBio);
    }

    setQueSlide(queSlide + 1);
    getCurrentQuestions(queSlide + 1 - 2);
    // console.log(queSlide);
    setCurrSlideNum(queSlide);
    updateData();
  };

  const prevSlide = () => {
    setQueSlide(queSlide - 1);
    getCurrentQuestions(queSlide - 1 - 2);
    // console.log(queSlide);
    setCurrSlideNum(queSlide - 2);
    //   setCurrSlideNum(queSlide);
  };

  const getCurrentQuestions = (currSlide) => {
    const a = currSlide * quePerPage;
    const b = a + quePerPage;
    const currentQuestionArr = questionList.slice(a, b);
    setCurrQuestionList(currentQuestionArr);
    // console.log(currentQuestionArr);
  };

  const showOptionList = async (e) => {
    let optionListOverlay =
      e.target.parentNode.querySelector(".optionListOverlay");
    optionListOverlay.style.transform = "scale(1)";
    let options = e.target.parentNode.querySelectorAll(
      ".multiOptions[selected=true]"
    );
    // console.log("all options", e.target.parentNode);

    options.forEach((elem) => {
      elem.click();
    });

    let qLabel = e.target.getAttribute("qLabel");

    try {
      const token = localStorage.getItem("accessToken");
      const userResponse = await myApi.get("/panel/ProfileIndia/profilePanel", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let val = getOptionNum(qLabel, userResponse.data.result.panelData);
      // console.log(val);
      let allOptions = document.querySelectorAll(".multiOptions");
      allOptions.forEach(async (elem) => {
        if (val.includes(parseInt(elem.getAttribute("value")))) {
          elem.click();
          if (elem.getAttribute("type") === "open_end") {
            let input = elem.querySelector("input");
            const openEndVal = await getOpenEndValue(
              elem.getAttribute("qLabel")
            );
            input.value = openEndVal;
          }
        }
      });
    } catch (error) {
      console.log(error);
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
  };

  const hideOptionList = (e) => {
    let optionListOverlay = e.target.parentNode.parentNode;
    optionListOverlay.style.transform = "scale(0)";
  };

  const handleFileChange = (event) => {
    const navImg = document.querySelectorAll(".userImgCont img");
    const profileImg = document.querySelector(".imgCont .imgDiv img");
    const file = event.target.files[0];
    const token = localStorage.getItem("accessToken");

    // Check if a file is selected
    if (file) {
      const formData = new FormData();
      formData.append("profile_image", file);

      myApi
        .post("/panel/ProfileIndia/profileImg", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.profileImage);
          const originalURL = myApi.defaults.baseURL;
          const modifiedURL = originalURL.replace("/FifthPoll/api", "");
          const picUrl = modifiedURL + response.data.profileImage;
          setSelectedImage(picUrl);
          console.log(navImg.length)
          navImg.forEach((elem)=>{
            elem.setAttribute("src", picUrl);
          })
          

        })
        .catch((error) => {
          if (localStorage.getItem("refreshToken") != null) {
            console.log("Token is expired");
            renewAccessToken();
          }
        });
      const reader = new FileReader();
      // Read the selected file as a data URL
      reader.readAsDataURL(file);
      // Set the state with the selected image
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        navImg.setAttribute("src", reader.result);
        profileImg.setAttribute("src", render.result);
      };
    } else {
      // If no file is selected, clear the image
      // setSelectedImage(null);
      // console.log("No file selected");
    }
  };

  const changeMultipleOptions = async (e, qLabel) => {
    let multiOptions = e.target.parentNode.querySelectorAll(
      ".multiOptions[selected=true]"
    );
    // let allMultiOptions = document.querySelectorAll(".multiOptions");
    let openText = "";
    if (multiOptions.length > 0) {
      // console.log(e.target)
      // let qLabel = e.target.getAttribute("qLabel");
      let valArr = [];
      let openEnd = false;
      multiOptions.forEach((elem) => {
        valArr.push(parseInt(elem.getAttribute("value")));
        if (elem.getAttribute("type") === "open_end") {
          // console.log("openEnd ", elem);
          openText = elem.querySelector("input").value;
          openEnd = true;
        }
      });

      if (openText.trim() === "" && openEnd) {
        showErrorCard("Please enter something in other.", "error");
        return;
      }
      e.target.parentNode.style.transform = "scale(0)";
      let newData;
      if (openEnd) newData = await selectionChange(qLabel, valArr, openText);
      else newData = await selectionChange(qLabel, valArr);

      // await setCurrentUser(newData)
      changeBtnText(newData.result.panelData);
    }

    if (multiOptions.length == 0) {
      showErrorCard("Please select atleast one option.", "error");
    }

    // const elem = e.target.parentNode.parentNode.parentNode.parentNode;
    // const multiOptBtn = elem.querySelector(".multiOptBtn");
    // const multiOptions = elem.querySelectorAll(".multiOptions[selected=true]");
    // const questionText = elem.querySelector(".questionText");
    // let ans = "";
    // for (let i = 0; i < multiOptions.length; i++) {
    //   let listText = multiOptions[i].querySelector(".listText").innerText;
    //   ans += listText.split(" ")[0] + ",";
    // }
    // ans = ans.slice(0, ans.length - 1);
    // multiOptBtn.innerText = ans;

    // const qLabel = questionText.getAttribute("qLabel");

    // const idToChange = qLabel;
    // console.log(qLabel);
    // const newVal = true;
    // // console.log(elem);
    // const newArray = questionState.map((item) => {
    //   if (item.label == idToChange) {
    //     // Change the value of the 'name' property for the matching object
    //     return { ...item, value: newVal };
    //   }
    //   return item;
    // });
    // console.log(newArray)
    // setQuestionState(newArray);
  };

  const getNewUserData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const userResponse = await myApi.get("/panel/ProfileIndia/profilePanel", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return userResponse.data;
    } catch (err) {
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
  };
  // const changeOptionStatus = ()=>{
  //     // let optionList = overlay.querySelector('.optionDiv');
  //     alert("called")
  //     let allMultiOptions = document.querySelectorAll(".multiOptions");
  //     for(let i=0;i<allMultiOptions.length;i++){
  //         allMultiOptions[i].addEventListener('click',()=>{
  //             if(allMultiOptions[i].hasAttribute("selected")){
  //                 alert("it has")
  //                 allMultiOptions[i].removeAttribute("selected");
  //                 allMultiOptions[i].style.background = "none";
  //                 allMultiOptions[i].style.color = "revert";
  //                 let icon = allMultiOptions[i].children[0];
  //                 icon.setAttribute("class","fa-regular fa-square");

  //             }else{
  //                 alert("not its has not")

  //                 allMultiOptions[i].setAttribute("selected",true);
  //                 allMultiOptions[i].style.background = "var(--iconCol)";
  //                 allMultiOptions[i].style.color = "white";
  //                 let icon = allMultiOptions[i].children[0];
  //                 icon.setAttribute("class","fa-solid fa-square-check");
  //                 // icon.setAttribute("style","color: #1ab106;")
  //                 // <i class="fa-regular fa-square-check" style="color: #1ab106;"></i>
  //             }
  //         })
  //     }
  // }

  const getQuestionState = (qLabel) => {
    const data = currentUser.result.panelData;
    for (var i = 0; i < data.length; i++) {
      var obj = data[i];
      var objKey = Object.keys(obj)[0]; // Assuming each object contains only one key
      if (objKey === qLabel) {
        return obj[qLabel].status;
      }
    }
  };

  const getMultipleOptionText = (qLabel) => {
    // const keyToFind = "qLabel";
    // const valueToFind = qLabel;
    // const foundObject = questionState.find(
    //   (item) => item[keyToFind] === valueToFind
    // );
    // return foundObject.btnText;
    return "Please Select";
  };
  const changeOptionStatus = (e, optionVal) => {
    // if(e.target.classList.contains("openEnd")) return;
    const exclusiveCode = [99, 96];
    let elem = e.target;
    if (!e.target.classList.contains("multiOptions"))
      elem = e.target.parentNode;
    if (
      exclusiveValues.includes(elem.getAttribute("type")) &&
      elem.hasAttribute("selected")
    ) {
      // alert("first")
      deselectOption(elem);
    } else if (exclusiveValues.includes(elem.getAttribute("type"))) {
      // alert("second")
      const allOptions =
        elem.parentNode.parentNode.querySelectorAll(".multiOptions");
      for (let i = 0; i < allOptions.length; i++) {
        deselectOption(allOptions[i]);
      }
      selectOption(elem);
    } else if (elem.hasAttribute("selected")) {
      // alert("third")
      deselectOption(elem);
    } else {
      selectOption(elem);
      // console.log(elem)
      const allOptions =
        elem.parentNode.parentNode.querySelectorAll(".multiOptions");
      // console.log(allOptions);
      for (let i = 0; i < allOptions.length; i++) {
        // console.log(allOptions[i]);
        if (exclusiveValues.includes(allOptions[i].getAttribute("type"))) {
          deselectOption(allOptions[i]);
        }
      }
    }
  };

  const selectOption = (elem) => {
    elem.setAttribute("selected", true);
    elem.style.background = "var(--iconCol)";
    elem.style.color = "white";
    let icon = elem.children[0];
    let optionVal = elem.getAttribute("type");
    if (exclusiveValues.includes(optionVal)) {
      icon.setAttribute("class", "fa-solid fa-circle-check");
    } else {
      icon.setAttribute("class", "fa-solid fa-square-check");
    }
  };

  const deselectOption = (elem) => {
    elem.removeAttribute("selected");
    elem.style.background = "none";
    elem.style.color = "revert";
    let icon = elem.children[0];
    let optionVal = elem.getAttribute("type");

    if (exclusiveValues.includes(optionVal)) {
      icon.setAttribute("class", "fa-regular fa-circle");
    } else {
      icon.setAttribute("class", "fa-regular fa-square");
    }
  };

  const updateData = () => {
    const dataArr = [];
    let question = document.querySelectorAll(".question");
    for (let i = 0; i < question.length; i++) {
      let qLabel = question[i].getAttribute("qLabel");
      let allMultiOptions = document.querySelectorAll(
        '.multiOptions[selected="true"]'
      );
      for (let j = 0; j < allMultiOptions.length; j++) {
        let value = allMultiOptions[j].getAttribute("value");
        let optionArr = [...value];
      }
    }
  };

  if (currentUser)
    return (
      // <div className="panDashboard dfr">
      //   <DashboardSidebar active="dashboard" />
      //   <div className="right">
      //     <DashboardTop head="Your Profile" />
      //     <div className="mainCont dfr">
      <>
        {loading && <Loader />}
        <div
          className="left"
          style={{ width: "95%", height: "100%", boxShadow: "none" }}
        >
          <div className="profileTop flex">
            <div
              className="category"
              style={{ color: darkTheme && "var(--darkText)" }}
            >
              {pageHead}
              {`${category[currSlide]} `}{" "}
              {(currSlide == 1 || currSlide == 0) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="34"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "rgba(127, 140, 141,1.0)",
                    transform: "msFilter",
                  }}
                >
                  <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
                  <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
                </svg>
              )}
            </div>
            {/* <div style={{ marginLeft: "2%" }}>YOUR PROFILE</div> */}

            <div className="colorSlider">
              {totalSlides?.map((m, i) => {
                return (
                  <div
                    className={queSlide >= i + 1 && "completed"}
                    onClick={() => {
                      if (currSlide === i) return;
                      setQueSlide(i + 1);
                      getCurrentQuestions(i - 1);
                      setCurrSlideNum(i);
                    }}
                  >
                    <span className="imageCont dfc" style={{width:'100%',height:'100%'}}>
                      {i == 0 || i == 1 ? (
                        <>
                        <p className='iconCont' style={{width:'100%',height:'100%'}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="55"
                          height="34"
                          viewBox="0 0 24 24"
                          style={{
                            fill: "rgba(127, 140, 141,1.0)",
                            transform: "msFilter",
                            maxHeight: "100%",
                          }}
                        >
                          <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
                          <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
                        </svg>
                        </p>
                         
                        <p className='pageHeader' style={{display:'none',textWrap:'nowrap',position:'absolute'}}>{pageHeadList[i]}</p>
                        </>
                       
                      ) : (
                        <>
                         <p className='iconCont' style={{width:'100%',height:'100%',textAlign:'center'}}>
                       {category[i]}
                        </p>
                         
                        <p  clasName='pageHeader' style={{textWrap:'nowrap',display:'none',position:'absolute'}}>{pageHeadList[i]}</p>

                        </>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mainContPar profile">
            {queSlide === 1 && (
              <div className="mainCont">
                <div className="imgCont">
                  <div
                    className="imgDiv"
                    onMouseOver={() => setUploadDis(true)}
                    onMouseLeave={() => setUploadDis(false)}
                  >
                    <img alt="User" src={selectedFile} />
                    <div
                      className="iconCont"
                      style={{ opacity: uploadDis ? 1 : 0 }}
                    >
                      <i
                        className="fa-solid fa-camera editBtn"
                        onClick={() =>
                          document.querySelector(".uploadPicBtn").click()
                        }
                      ></i>
                    </div>

                    <input
                      className="uploadPicBtn"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleFileChange}
                    />
                  </div>

                  <div
                    className="userName"
                    style={{ color: darkTheme && "var(--darkText)" }}
                  >
                    {currentUser.result.name}
                  </div>
                  <div
                    className="profileIntro"
                    style={{
                      color: darkTheme && "var(--darkText)",
                      border: darkTheme && "1px solid var(--darkText)",
                    }}
                  >
                    {bio}
                  </div>
                  <div
                    className="fc"
                    style={{ height: "10%", minWidth: "100%" }}
                  >
                    {" "}
                    <i
                      className="fa-solid fa-circle-info"
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        marginRight: "3%",
                      }}
                      onMouseOver={() => setPersonInfo(true)}
                      onMouseLeave={() => setPersonInfo(false)}
                    ></i>
                    <div style={{ color: darkTheme && "var(--darkText)" }}>
                      Personality Statement
                    </div>
                    <div
                      className="perSliderInfo"
                      style={{ display: personInfo ? "block" : "none" }}
                    >
                      Please fill personality slider to generate personality
                      statement
                    </div>
                  </div>
                </div>

                <div className="basicInfo">
                  <div style={{ color: darkTheme && "var(--darkText)" }}>
                    Basic Information
                  </div>

                  <div
                    className="infoCont"
                    style={{
                      color: darkTheme && "var(--darkText)",
                      border: darkTheme && "1px solid var(--darkText)",
                    }}
                  >
                    {currQuestionList?.map((m) => {
                      return (
                        <div className="infoField">
                          <label htmlFor="">{m.Question_subTitle}</label>
                          {m.qType === "date" && (
                            <input
                              type="date"
                              qLabel={m.qLabel}
                              style={{ pointerEvents: "none", opacity: 0.6 }}
                            />
                          )}
                          {m.qType === "numeric" && (
                            <input
                              type="number"
                              qLabel={m.qLabel}
                              minLength={6}
                              maxLength={6}
                              onBlur={(e) =>
                                changeBasicInfo(
                                  m.qLabel,
                                  m.qType,
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          )}
                          {m.qType === "single" && (
                            <>
                              <select
                                className={m.qType}
                                style={
                                  m.qLabel === "Gender"
                                    ? { pointerEvents: "none", opacity: 0.6 }
                                    : {}
                                }
                                onChange={(e) =>
                                  changeBasicInfo(
                                    m.qLabel,
                                    m.qType,
                                    parseInt(e.target.value)
                                  )
                                }
                                name=""
                                id=""
                                qLabel={m.qLabel}
                                qType={m.qType}
                              >
                                <option value="0">Please Select</option>
                                {m.option_list?.map((f) => {
                                  return (
                                    <option value={f.id}>
                                      {f.translation}
                                    </option>
                                  );
                                })}
                              </select>
                            </>
                          )}
                        </div>
                      );
                    })}

                    {/* <div className="infoField">
                    <label htmlFor="">Gender</label>
                    <select>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="98">Other</option>
                      <option value="96">Prefer not to say</option>
                    </select>
                  </div>

                  <div className="infoField">
                    <label htmlFor="">Income</label>
                    <select>
                      <option value="1">Less than Rs. 3 lakhs</option>
                      <option value="2">Rs. 3 to Less than 5 lakhs</option>
                      <option value="3">Rs. 5 to Less than 7.5 lakhs</option>
                      <option value="4">Rs. 7.5 to Less than 10 lakhs</option>
                      <option value="5">Rs. 10 to Less than 12.5 lakhs</option>
                      <option value="6">Rs. 12.5 to Less than 15 lakhs</option>
                      <option value="7">Rs. 15 to Less than 17.5 lakhs</option>
                      <option value="8">Rs. 17.5 to Less than 20 lakhs</option>
                      <option value="9">More than Rs. 20 lakhs</option>
                      <option value="97">Not sure / Don t know</option>
                      <option value="96">Prefer not to say</option>
                    </select>
                  </div>

                  <div className="infoField">
                    <label htmlFor="">Education</label>
                    <select>
                      <option value="1">Elementary School</option>
                      <option value="2">
                        Secondary School (Includes Standards X and XII -
                        Secondary School or Senior Secondary School)
                      </option>
                      <option value="3">
                        First University Degree (Bachelor's)
                      </option>
                      <option value="4">
                        First University Degree (Engineering & Technology)
                      </option>
                      <option value="5">
                        Second University Degree (Master's)
                      </option>
                      <option value="6">Doctoral Degree</option>
                    </select>
                  </div>

                  <div className="infoField">
                    <label htmlFor="">Occupation</label>
                    <select>
                      <option value="1"> Student</option>
                      <option value="2"> Salaried</option>
                      <option value="3"> Businessman</option>
                      <option value="4">
                        {" "}
                        Self-employed Professionals like Doctors, Architects,
                        Chartered Accountants etc.
                      </option>
                      <option value="5"> Unemployed</option>
                      <option value="6"> Homemaker</option>
                    </select>
                  </div>

                  <div className="infoField">
                    <label htmlFor="">Location</label>
                    <select>
                      {countries.map((m, i) => (
                        <option value={i}>{m}</option>
                      ))}
                    </select>
                  </div>

                  <div className="infoField">
                    <label htmlFor="">Marital Status</label>
                    <select>
                      <option value="">Single</option>
                      <option value="">Married</option>
                    </select>
                  </div> */}
                  </div>
                </div>
                <div
                  className="sliderCont"
                  style={{ color: darkTheme && "var(--darkText)" }}
                >
                  <div>Personality Slider</div>
                  <div
                    className="infoCont"
                    style={{
                      color: darkTheme && "var(--darkText)",
                      border: darkTheme && "1px solid var(--darkText)",
                    }}
                  >
                    <div className="infoField">
                      <div>Introvert</div>
                      <div>Extrovert</div>
                      <div className="circleDiv">
                        {totalPoints?.map((m, i) => {
                          return (
                            <div
                              onClick={() => changeSelectedCircle("s1", i + 1)}
                              key={i}
                              className={
                                points.s1 === i + 1 ? "circle dark" : "circle"
                              }
                            ></div>
                          );
                        })}
                      </div>
                    </div>{" "}
                    <div className="infoField">
                      <div>Creative</div>
                      <div>Analytical</div>
                      <div className="circleDiv">
                        {totalPoints?.map((m, i) => {
                          return (
                            <div
                              onClick={() => changeSelectedCircle("s2", i + 1)}
                              key={i}
                              className={
                                points.s2 === i + 1 ? "circle dark" : "circle"
                              }
                            ></div>
                          );
                        })}
                      </div>
                    </div>{" "}
                    <div className="infoField">
                      <div>Messy</div>
                      <div>Organized</div>
                      <div className="circleDiv">
                        {totalPoints?.map((m, i) => {
                          return (
                            <div
                              onClick={() => changeSelectedCircle("s3", i + 1)}
                              key={i}
                              className={
                                points.s3 === i + 1 ? "circle dark" : "circle"
                              }
                            ></div>
                          );
                        })}
                      </div>
                    </div>{" "}
                    <div className="infoField">
                      <div>Emotional</div>
                      <div>Rational</div>
                      <div className="circleDiv">
                        {totalPoints?.map((m, i) => {
                          return (
                            <div
                              onClick={() => changeSelectedCircle("s4", i + 1)}
                              key={i}
                              className={
                                points.s4 === i + 1 ? "circle dark" : "circle"
                              }
                            ></div>
                          );
                        })}
                      </div>
                    </div>{" "}
                    <div className="infoField">
                      <div>Active</div>
                      <div>Passive</div>
                      <div className="circleDiv">
                        {totalPoints?.map((m, i) => {
                          return (
                            <div
                              onClick={() => changeSelectedCircle("s5", i + 1)}
                              key={i}
                              className={
                                points.s5 === i + 1 ? "circle dark" : "circle"
                              }
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="infoField">
                      <div>Play Safe</div>
                      <div>Takes Risk</div>
                      <div className="circleDiv">
                        {totalPoints?.map((m, i) => {
                          return (
                            <div
                              onClick={() => changeSelectedCircle("s6", i + 1)}
                              key={i}
                              className={
                                points.s6 === i + 1 ? "circle dark" : "circle"
                              }
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {queSlide > 1 && (
              <div className="mainCont">
                <div className="questionsDiv">
                  {currQuestionList?.map((m) => {
                    return (
                      <div
                        className="question"
                        qLabel={m.qLabel}
                        style={
                          m.qLabel == "Child_Household_U18_IN"
                            ? { display: dQues ? "flex" : "none" }
                            : {}
                        }
                      >
                        <div
                          qLabel={m.qLabel}
                          className="questionText"
                          style={{
                            background: getQuestionState(m.qLabel)
                              ? "rgb(63,173,138)"
                              : "white",
                            color: getQuestionState(m.qLabel)
                              ? "white"
                              : "black",
                          }}
                        >
                          {m.Question_title}
                        </div>
                        {m.qType === "single" && (
                          <div className={`optionDiv`}>
                            <select
                              className={`${m.qType}`}
                              name=""
                              id=""
                              qLabel={m.qLabel}
                              qType={m.qType}
                              onChange={(e) => {
                                selectionChange(m.qLabel, [
                                  parseInt(e.target.value),
                                ]);
                                changeQuestionColor(e);
                                if (m.qLabel == "Child_Household_IN")
                                  dependentQuestionChange(e.target.value);
                              }}
                            >
                              <option value="0">Please Select</option>
                              {m.option_list?.map((f) => {
                                return <option value={f.id}>{f.title}</option>;
                              })}
                            </select>
                          </div>
                        )}

                        {m.qType === "multi" && (
                          <div className="optionDiv">
                            <div
                              className={`multiOptBtn ${m.qType}`}
                              qlabel={`${m.qLabel}`}
                              onClick={(e) => showOptionList(e)}
                            >
                              {getMultipleOptionText(m.qLabel)}
                            </div>
                            <div className="optionListOverlay">
                              <div className="overlayBtnCont">
                                <div>{m.Question_title}</div>

                                {/* <i
                                className="fa-solid fa-check close"
                                onClick={(e) => changeMultipleOptions(e)}
                                style={{ color: "var(--iconCol) !important" }}
                              ></i> */}

                                <i
                                  className="fa-solid fa-xmark close"
                                  onClick={(e) => hideOptionList(e)}
                                ></i>
                              </div>

                              <div className="optionList flex">
                                {isMobile ? (
                                  <>
                                    {m.option_list?.map((f, i) => (
                                      <div
                                        className="flex multiOptions"
                                        value={f.id}
                                        type={f.option_type}
                                        qLabel={m.qLabel}
                                        onClick={(e) =>
                                          changeOptionStatus(e, parseInt(f.id))
                                        }
                                      >
                                        {exclusiveValues.includes(
                                          f.option_type
                                        ) ? (
                                          <i
                                            style={{ pointerEvents: "none" }}
                                            className="fa-regular fa-circle"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ pointerEvents: "none" }}
                                            className="fa-regular fa-square"
                                          ></i>
                                        )}
                                        <div
                                          className="listText"
                                          style={{ pointerEvents: "none" }}
                                          value={f.id}
                                        >
                                          {f.title}
                                        </div>

                                        {f.id == 98 && (
                                          <input
                                            style={{ marginLeft: "5%" }}
                                            type="text"
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div style={{ width: "48%" }}>
                                      {m.option_list?.map(
                                        (f, i) =>
                                          i < m.option_list.length / 2 && (
                                            <div
                                              className="flex multiOptions"
                                              value={f.id}
                                              type={f.option_type}
                                              qLabel={m.qLabel}
                                              onClick={(e) =>
                                                changeOptionStatus(
                                                  e,
                                                  parseInt(f.id)
                                                )
                                              }
                                            >
                                              {exclusiveValues.includes(
                                                f.option_type
                                              ) ? (
                                                <i
                                                  style={{
                                                    pointerEvents: "none",
                                                  }}
                                                  className="fa-regular fa-circle"
                                                ></i>
                                              ) : (
                                                <i
                                                  style={{
                                                    pointerEvents: "none",
                                                  }}
                                                  className="fa-regular fa-square"
                                                ></i>
                                              )}
                                              <div
                                                className="listText"
                                                style={{
                                                  pointerEvents: "none",
                                                }}
                                                value={f.id}
                                              >
                                                {f.title}
                                              </div>

                                              {f.id == 98 && (
                                                <input
                                                  style={{ marginLeft: "5%" }}
                                                  type="text"
                                                />
                                              )}
                                            </div>
                                          )
                                      )}
                                    </div>

                                    <div style={{ width: "48%" }}>
                                      {m.option_list?.map(
                                        (f, i) =>
                                          i >= m.option_list.length / 2 && (
                                            <div
                                              className="flex multiOptions"
                                              value={f.id}
                                              type={f.option_type}
                                              qLabel={m.qLabel}
                                              onClick={(e) =>
                                                changeOptionStatus(
                                                  e,
                                                  parseInt(f.id)
                                                )
                                              }
                                            >
                                              {exclusiveValues.includes(
                                                f.option_type
                                              ) ? (
                                                <i
                                                  style={{
                                                    pointerEvents: "none",
                                                  }}
                                                  className="fa-regular fa-circle"
                                                ></i>
                                              ) : (
                                                <i
                                                  style={{
                                                    pointerEvents: "none",
                                                  }}
                                                  className="fa-regular fa-square"
                                                ></i>
                                              )}
                                              {/* <i style={{pointerEvents:'none'}} className="fa-regular fa-square"></i> */}
                                              <div
                                                className="listText"
                                                style={{
                                                  pointerEvents: "none",
                                                }}
                                                value={f.id}
                                              >
                                                {f.title}
                                              </div>
                                              {f.option_type == "open_end" && (
                                                <input
                                                  className="openEnd"
                                                  style={{ marginLeft: "5%" }}
                                                  type="text"
                                                />
                                              )}
                                            </div>
                                          )
                                      )}
                                    </div>
                                  </>
                                )}

                                {/* <div style={{width:"48%"}}>
                                {m.options.map((f)=>{
                                    return <div className="flex multiOptions" value={f.value} onClick={(e)=>changeOptionStatus(e,parseInt(f.value))}>
                                        <i style={{pointerEvents:'none'}} className="fa-regular fa-square"></i>
                                        <div className="listText" style={{pointerEvents:'none'}} value={f.value}>{f.text}</div>
                                        {f.value == 98 && <input style={{marginLeft:'5%'}} type="text" /> } 
                                     </div>
                                    })}
                              </div> */}
                              </div>
                              <div
                                className="saveBtn"
                                onClick={(e) =>
                                  changeMultipleOptions(e, m.qLabel)
                                }
                                style={{ color: "var(--iconCol) !important" }}
                              >
                                Done
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div
              className="flex updateBtnCont"
              style={{ justifyContent: "space-around"}}
            >
              <div
                style={{ visibility: queSlide !== 1 ? "visible" : "hidden" }}
                className="updateBtn proUpdate flex"
                onClick={() => prevSlide()}
              >
                <div className="iconDiv dfc">
                  {" "}
                  <i className="fa-solid fa-backward"></i>
                </div>
              </div>
              {/* <div className="updateBtn proUpdate flex" onClick={()=>updateData()}>
                    Update
                    <div className="iconDiv dfc">
                      {" "}
                      <i className="fa-solid fa-arrows-rotate"></i>
                    </div>
                  </div> */}

              <div
                style={{
                  visibility: queSlide <= slideNum ? "visible" : "hidden",
                }}
                className="updateBtn proUpdate flex"
                onClick={() => nextSlide()}
              >
                <div className="iconDiv dfc">
                  {" "}
                  <i className="fa-solid fa-forward"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      /* <TrendingSurvey/> */
      //     </div>
      //   </div>
      // </div>
    );
}

export default Profile;

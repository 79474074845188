import React, { useEffect,useState } from 'react'
import panLogo from "../images/panelLogo.png";
import { useActiveContext } from './ActiveContext';
import useCurrentUser from './useCurrentUser';
import userMale from "../images/userMale.png"
import userFemale from "../images/userFemale.png"
import myApi from './MyApi';
import useIsMobile from './useIsMobile';
import { useNavigate } from 'react-router-dom';
import panLogoDark from "../images/panelLogoDark.png"
import Loader from './Loader';


export default function DashboardSidebar() {
    const {active,setActive,sideBarDis,setSideBarDis,darkTheme} = useActiveContext();
    const [currentUser, setCurrentUser] = useCurrentUser();
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [loading,setIsLoading] = useState();
    // const {darkTheme} = useActiveContext();
    const handleLogout = async ()=>{
      setIsLoading(true)
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      
     await myApi
      .post('/account/logout', {
        refresh_token:refreshToken
      },{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          window.location.href = (window.location.href).split('/').slice(0, 3).join('/')+"/login";
          setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
  
      });
    }
  

    const changeMidComp = (compName)=>{
      setActive(compName);
      navigate(`/${compName}`)
      isMobile && setSideBarDis(false);
    }

   
  return (
   <>
   {loading && <Loader/>}
     <div style={{
      transform: sideBarDis ? "translateX(0%)" : "translateX(-100%)",
      background: darkTheme ? "var(--darkBg1)" : "white"
    }} className="left panDashboardSidebar">
    <div className="panLogoDiv dfc"  style={{cursor:active !=='dashboard'&&'pointer'}} onClick={()=>changeMidComp("dashboard")}>
      <img className="panLogo" src={darkTheme?panLogoDark:panLogo} alt=""  />
      <div style={{color:'var(--iconCol)'}}>Every opinion matter</div>
    </div>

    <div className="menuDiv">
      <div className="top dfc">
      <a className="profileSideBar" onClick={()=>{changeMidComp("profile")}}>
      <div className="sideUser">
      {currentUser&&<>
      <a className="pointer" onClick={()=>changeMidComp("profile")}><div className="userImgCont">
       <img alt="User" src={currentUser.result.profilePic} />
        <div style={{color:darkTheme&&'var(--darkText)'}}>{currentUser.result.name}</div>
        
      </div></a>
     
       </>}
      
    </div>
        </a>
        <a onClick={()=>{changeMidComp("dashboard")}} className={darkTheme && "dark"}>
          <i className={active==='dashboard'?'fa-solid fa-square-poll-horizontal active':'fa-solid fa-square-poll-horizontal'}></i> Dashboard
        </a>
        <a onClick={()=>{changeMidComp("message")}} className={darkTheme && "dark"}>
          <i  className={active==='message'?"fa-solid fa-envelope active":"fa-solid fa-envelope"}></i> Message
        </a>
        <a onClick={()=>{changeMidComp("redeem")}} className={darkTheme && "dark"}>
          {" "}
          <i className={active==='redeem'?"fa-solid fa-gift active":"fa-solid fa-gift"}></i> Redeem

        </a>
       
      </div>
      <div className="mid dfc">
        <a onClick={()=>{changeMidComp("myAccount")}} className={darkTheme && "dark"}>
          <i className={active==='myAccount'?"fa-solid fa-user active":"fa-solid fa-user"}></i> My Account
        </a>
        <a onClick={()=>{changeMidComp("settings")}} className={darkTheme && "dark"}>
          <i className={active==='settings'?"fa-solid fa-gear active":"fa-solid fa-gear"}></i> Settings
        </a>
      </div>
      <div className="bottom dfc">
        <a onClick={()=>{changeMidComp("help")}} className={darkTheme && "dark"}>
        <i className={active==='help'?"fa-solid fa-question active":"fa-solid fa-question"}></i>Help & Support 
        </a>
        <a onClick={handleLogout} className={darkTheme && "dark"} style={{marginTop:'10%'}}><i class="fa-solid fa-power-off"></i> Log out</a>
      </div>
    </div>

  </div>
   </>
  
  )
}

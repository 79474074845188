import React,{useState} from 'react'
import ForgotImg from '../images/forgotPassPan.png'
import panLogo from '../images/panelLogo.png'
import myApi from './MyApi';
import { showErrorCard } from '../App';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

export default function ResetPass() {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false);
    const handleResetPass = async (e)=>{
        setLoading(true)
        e.preventDefault();
        try{
            const email = e.target.resetEmail.value;
            await myApi.post('/account/restPasswordMail',{
                email
            });
            showErrorCard("Reset Password link is successfully sent to your registered email","success");
            e.target.reset()
            setLoading(false)
        }catch(err){
            showErrorCard(err.response.data.errors,"error");
            console.log(err)
            setLoading(false)
        }
       
    }
  return (
    <>
    {loading && <Loader/>}
 <div className='forgotPan dfr'>
        <div className='left dfr'>
        <div className='dfc' style={{width:'13%',position:'absolute',top:'2%',left:'12%'}} onClick={()=>navigate('/')} >
          <img  src={panLogo} alt="" style={{maxWidth:'100%'}} />
          <div style={{ color: "var(--iconCol)", textWrap: "nowrap" }}>
            Every opinion matter
          </div>
        </div>
        <div className='forgotFieldCont dfc'>
            <div>Forgot Password</div>
            <form onSubmit={(e)=>handleResetPass(e)}>
                <input type="email" name="resetEmail" placeholder='Enter your email' required/>
                <br />
                {/* <input type="password" placeholder='Confirm New Password' />
                <br /> */}

                <button className='forgotBtn'>Submit</button>
            </form>
        </div>
        </div>
        <div className='right dfc'>
            <img src={ForgotImg} alt="" />
        </div>
    </div>
    </>
   
  )
}


import React from 'react'
import panLogo from "../images/panelLogo.png";
import { useNavigate } from 'react-router-dom';

export default function Logo() {
    const navigate = useNavigate();
   
  return (
    <div className='logoComp' >
        <img  src={panLogo} alt="" onClick={()=>navigate("/")} />
        <div className='logoText' style={{color:'var(--iconCol)',textWrap:'nowrap'}}>Every opinion matter</div>
    </div>

  )
}

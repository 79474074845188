import React, { useState, useEffect } from "react";
import DashboardSidebar from "./DashboardComp";
import DashboardTop from "./DashboardTop";
import TrendingSurvey from "./TrendingSurvey";
import useIsMobile from "./useIsMobile";
import "../css/myAccount.css";
import { useNavigate } from "react-router-dom";
import { getData, postData, patchData, showErrorCard } from "../App";
import Overlay from "./Overlay";
import { useActiveContext } from "./ActiveContext";
import Loader from "./Loader";
import { currLang, currCountry } from "./utils";
import AddPincode from "./AddPincode";

export default function MyAccount() {
  const navigate = useNavigate();
  const [profileComp, setProfileComp] = useState(30);
  const [mobNum, setMobNum] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const isMobile = useIsMobile();
  const [verifyDis, setVerifyDis] = useState(false);
  const [fieldDis, setFieldDis] = useState({
    changePass: false,
    addPhone: false,
    address: false,
  });
  const [stateList, setStateList] = useState({});
  const [cityList, setCityList] = useState({});
  const [verificationCode, setVerificationCode] = useState("");
  const [countryList, setCountryList] = useState([]);
  const { darkTheme } = useActiveContext();
  const [isLoading, setIsLoading] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [state, setState] = useState(0);
  const [pinCode, setPinCode] = useState("");
  const [errorPinMessage, setErroPinMessage] = useState(false);
  const [addPinDis, setAddPinDis] = useState(false);
  const [openValues, setOpenValues] = useState({
    region: "0",
    division: "0",
    district: "0",
    block: "0",
    area: "0",
  });
  const [activeTab, setActiveTab] = useState(1);

  const handleAddAddress = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const {
      state,
      region,
      division,
      district,
      block,
      area,
      pincode,
      locality,
      landmark,
      address,
    } = e.target;
    let data = {
      Country: "India",
      State: state.value,
      // "Region":region.value,
      // "Division":division.value,
      District: district.value,
      // "Block":block.value,
      // "Area":area.value,
      Pincode: pinCode,
      Locality: address.value,
      Near_By: landmark.value,
    };
    // console.log(data)
    const addressResp = await postData("/profile/address", data);
    if (addressResp.name === "AxiosError") {
      setIsLoading(false);
      showErrorCard("Address not added", "error");
    } else {
      setIsLoading(false);
      showErrorCard("Address successfully added", "success");
    }
    getAddresses();
    e.target.reset();
  };
  const handleResendOtp = async (verified, number) => {
    if (verified) return;
    setIsLoading(true);
    const resendResp = await postData("/profile/resendOtp", {
      number,
    });
    setIsLoading(false);
    setVerifyDis(true);
    setVerificationCode(resendResp.data.verificationCode);
    showErrorCard(resendResp.data.Message, "success");
  };
  const handleVerifyOtp = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let data = {
      code: verificationCode,
      OTP: e.target.otp.value,
    };
    let verifyResp = await postData(`/profile/otpVerify`, data);
    setIsLoading(false);
    if (verifyResp.name === "AxiosError") {
      return showErrorCard(verifyResp.response.data.errors, "error");
    }
    showErrorCard(verifyResp.data.success, "success");
    getPhoneNum();
    setVerifyDis(false);
  };
  // console.log(currentUser)
  const getStateList = async () => {
    const language = await currLang();
    // setUserLang(language);

    // Fetch and set the country asynchronously
    const countryName = await currCountry();
    const stateListResp = await getData(`/location/StateList?lang=${language}`);
    setStateList(stateListResp.data.result);
    // console.log(stateListResp)
    // console.log(stateListResp.data['option_list'])
    // const cityListResp = await getData("/panel/India/city/en/ALL");
    // setCityList(cityListResp.data);

    const countryResp = await getData(`/location/CountryList?lang=${language}`);
    console.log(countryResp.data.result);
    setCountryList(countryResp.data.result);
  };

  const getAddresses = async () => {
    const addressResp = await getData("/profile/address");
    setAddresses(addressResp.data.result);
  };

  const getPhoneNum = async () => {
    const mobListResp = await getData("/profile/phoneNo");
    setMobNum(mobListResp.data.result);
    // console.log(mobListResp.data);
  };
  const handleAddNumber = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    console.log(e.target.countryCode.value);
    let data = {
      countryCode: `+${e.target.countryCode.value}`,
      number: e.target.phoneNum.value,
    };
    const updatePhoneResp = await postData("/profile/phoneNo", data);
    if (updatePhoneResp.name === "AxiosError") {
      setIsLoading(false);
      showErrorCard(updatePhoneResp.response.data.errors, "error");
      return;
    }
    const verificationCode = updatePhoneResp.data.verificationCode;
    setVerificationCode(verificationCode);
    //  console.log(updatePhoneResp.data.verificationCode)
    setIsLoading(false);
    showErrorCard(updatePhoneResp.data.Message, "success");
    e.target.reset();
  };

  const handleOtpSubmission = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let data = {
      code: verificationCode,
      OTP: e.target.otp.value,
    };
    const otpUpdateResp = await postData(`/profile/otpVerify`, data);
    if (otpUpdateResp.name === "AxiosError") {
      setIsLoading(false);
      showErrorCard(otpUpdateResp.response.data.errors, "error");
    } else {
      setIsLoading(false);
      showErrorCard(otpUpdateResp.data.success, "success");
    }

    getPhoneNum();
    e.target.reset();
  };

  const handleChangePass = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let data = {
      oldPassword: e.target.old.value,
      password: e.target.new.value,
      password2: e.target.confirm.value,
    };
    let changePassResp = await postData("/account/changePassword", data);
    if (changePassResp.name === "AxiosError") {
      showErrorCard(changePassResp.response.data.errors, "error");
    } else {
      showErrorCard(changePassResp.data.success, "success");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
    setIsLoading(false);
    e.target.reset();
  };
  useEffect(() => {
    getStateList();
    getPhoneNum();
    getAddresses();
  }, []);

  const getRegionAndDivison = async (pincode) => {
    console.log(pincode);
    console.log(state);
    setIsLoading(true);
    try {
      const language = await currLang();
      const url =
        pincode == null
          ? `/location/PincodeList?lang=${language}&state=${state}`
          : `/location/PincodeList?lang=${language}&state=${state}&pincode=${pincode}`;
      const resp = await getData(url);
      console.log(resp);
      if (resp.data.Message) {
        setErroPinMessage(true);
        setRegionList([]);
        setDivisionList([]);
        setDistrictList([]);
        setBlockList([]);
        setAreaList([]);
      } else {
        setErroPinMessage(false);
        setRegionList(resp.data.Region);
        setDivisionList(resp.data.Division);
        setDistrictList(resp.data.District);
        setBlockList(resp.data.Block);
        setAreaList(resp.data.Area);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const changePincode = (e) => {
    const value = e.target.value;
    if (value.length <= 6) {
      setPinCode(value);
    }
    if (value.length == 6) {
      getRegionAndDivison(value);
    }
  };

  const changeState = (e) => {
    setState(e.target.value);
    setPinCode("");
  };
  // const getValuesOfAddPinCode = async (e)=>{
  //   const currState= e.target.value;
  //   setIsLoading(true);
  //   try {
  //     const language = await currLang();
  //     const url = `/location/PincodeList?lang=${language}&state=${currState}`;
  //     const resp = await getData(url)
  //       setRegionList(resp.data.Region);
  //       setDivisionList(resp.data.Division);
  //       setDistrictList(resp.data.District);
  //       setBlockList(resp.data.Block);
  //       setAreaList(resp.data.Area);

  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setIsLoading(false);

  // }

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setOpenValues(prevState => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  //  const handleAddPinCode = (e)=>{
  //   const {country,state,region,division,district,block,area,pincode} = e.target;
  //       const data = {

  //       }
  //       const resp = postData('location/UpdateAddress',data);
  //   }
  return (
    <>
      {isLoading && <Loader />}
      <div className="left myAccount thinScrollbar">
        {/* <div className="myAccountTag">My account <i class="fa-solid fa-user"></i></div> */}

        <div
          style={{ color: darkTheme && "var(--darkText)", cursor: "pointer" }}
          className="settingsCard"
          // onClick={() =>
          //   setFieldDis((prev) => ({
          //     addPhone: false,
          //     address: false,
          //     changePass: !prev.changePass,
          //   }))
          // }
        >
          {/* Change Password
          <i
            className={
              fieldDis.changePass
                ? "fa-solid fa-chevron-up"
                : "fa-solid fa-chevron-down"
            }
          ></i> */}
        
          <span
            className="detailsTab"
            style={{ background: activeTab === 1 && "var(--iconCol)",
              color:activeTab===1&&"white",
              boxShadow:activeTab===1 && "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"
             }}
            onClick={() => setActiveTab(1)}
          >
            Current Details
          </span>
          <span
            className="detailsTab"
            style={{ background: activeTab === 2 && "var(--iconCol)",color:activeTab===2&&"white" , boxShadow:activeTab===2 && "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}}
            onClick={() => setActiveTab(2)}
          >
            Phone Number
          </span>
          <span
            className="detailsTab"
            style={{ background: activeTab === 3 && "var(--iconCol)",color:activeTab===3&&"white", boxShadow:activeTab===3 && "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px" }}
            onClick={() => setActiveTab(3)}
          >
            Update Password
          </span>
          <span
            className="detailsTab"
            style={{ background: activeTab === 4 && "var(--iconCol)",color:activeTab===4&&"white", boxShadow:activeTab===4 && "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px" }}
            onClick={() => setActiveTab(4)}
          >
            Address
          </span>
        </div>

        {activeTab===3 &&
        <div
          className="passwordCont"
        >
           <div style={{ color: darkTheme && "var(--darkText)",margin:"2% 0" }}>Change password</div>
          <form onSubmit={handleChangePass}>
            <div style={{ color: darkTheme && "var(--darkText)" }}>
              Old Password
            </div>
            <input type="password" name="old" required />
            <div style={{ color: darkTheme && "var(--darkText)" }}>
              New Password
            </div>
            <input type="password" name="new" required />
            <div style={{ color: darkTheme && "var(--darkText)" }}>
              Confirm Password
            </div>
            <input type="password" name="confirm" />
            <button className="updateBtn">
              Update Password &nbsp; <i class="fa-solid fa-rotate"></i>
            </button>
          </form>
        </div>}

{activeTab===2 &&   
        <div
          className="passwordCont"
        >
         <div style={{ color: darkTheme && "var(--darkText)",margin:"2% 0" }}>Add phone number</div>
          <form onSubmit={handleAddNumber}>
            <input
              style={{ width: "20%" }}
              type="number"
              name="countryCode"
              required
              placeholder="use +91 for india"
            />
            <input
              style={{ width: "40%" }}
              type="number"
              name="phoneNum"
              required
              placeholder="Enter phone number"
            />
            <button className="otpBtn">Send OTP</button>
          </form>
          <br />

          <div className="flex"></div>
          <div style={{ color: darkTheme && "var(--darkText)" }}>
            Enter 4 digit OTP to update details
          </div>
          <form onSubmit={handleOtpSubmission}>
            <input
              type="number"
              name="otp"
              maxlength="5"
              style={{ width: "35%" }}
              required
            />

            <button className="updateBtn">
              Add number &nbsp;<i class="fa-solid fa-rotate"></i>
            </button>
          </form>
        </div>
}

{activeTab===4 &&   
        <div
          className="passwordCont" >
               <div style={{ color: darkTheme && "var(--darkText)",margin:"2% 0" }}>Add new address</div>
          <form onSubmit={handleAddAddress}>

            <textarea
              required
              name="address"
              id=""
              placeholder="Enter your house number and locality"
            ></textarea>

            <input
              type="text"
              placeholder="Enter your landmark (optional)"
              name="landmark"
            />
            <select
              name="state"
              id=""
              required
              value={state}
              onChange={changeState}
            >
              <option value="0" disabled selected hidden>
                Select your state
              </option>
              {stateList && Array.isArray(stateList)
                ? stateList.map((m) => (
                    <option key={m.id} value={m.translate}>
                      {m.translate}
                    </option>
                  ))
                : null}
            </select>
            <input
              required
              type="number"
              name="pincode"
              value={pinCode}
              placeholder="Enter your pincode"
              // onBlur={getRegionAndDivison}
              onChange={changePincode}
              disabled={state == 0}
            />
            {errorPinMessage && (
              <div className={darkTheme ? "dark" : ""}>
                Your pincode is not in our database
                <span
                  style={{
                    color: "var(--iconCol)",
                    cursor: "pointer",
                    margin: "10px",
                  }}
                  onClick={() => setAddPinDis(true)}
                >
                  click here
                </span>
                if you want to add this.
              </div>
            )}
            {/* <select
              name="region"
              id=""
              required
              disabled={regionList.length == 0}
            >
              <option value="0" disabled selected hidden>
                Select your region
              </option>
              {regionList?.map((m) => (
                <option key={m.translate} value={m.translate}>
                  {m.translate}
                </option>
              ))}
            </select> */}

            {/* <select
              name="division"
              id=""
              required
              disabled={divisionList.length == 0}
            >
              <option value="0" disabled selected hidden>
                Select your division
              </option>
              {divisionList.map((m) => (
                <option key={m.translate} value={m.translate}>
                  {m.translate}
                </option>
              ))}
            </select> */}

            <select
              name="district"
              id=""
              required
              disabled={districtList.length == 0}
            >
              <option value="0" disabled selected hidden>
                Select your district
              </option>
              {districtList.map((m) => (
                <option key={m.translate} value={m.translate}>
                  {m.translate}
                </option>
              ))}
            </select>

            {/* <select
              name="block"
              id=""
              required
              disabled={blockList.length == 0}
            >
              <option value="0" disabled selected hidden>
                Select your block
              </option>
              {blockList.map((m) => (
                <option key={m.translate} value={m.translate}>
                  {m.translate}
                </option>
              ))}
            </select> */}

            {/* <select name="area" id="" required disabled={areaList.length == 0}>
              <option value="0" disabled selected hidden>
                Select your area
              </option>
              {areaList.map((m) => (
                <option key={m.translate} value={m.translate}>
                  {m.translate}
                </option>
              ))}
            </select> */}

            {/* <input
            required
            type="text"
            placeholder="Enter your town"
            name="town"
          /> */}

            {/* <select name="city" id="" required>
          <option value="0">{cityList?.Question_title}</option>
            {cityList?.option_list && Array.isArray(cityList.option_list)
              ? cityList.option_list?.map((m) => (
                  <option key={m.val} value={m.val}>
                    {m.translation}
                  </option>
                ))
              : null}
          </select> */}

            <button className="updateBtn">
              Add address &nbsp; <i class="fa-solid fa-rotate"></i>
            </button>
          </form>
        </div>

}

{activeTab===1 && 
<>
{mobNum && mobNum.length > 0 && (
          <div className="mobileNumTable">
            <div style={{ color: darkTheme && "var(--darkText)" }}>
              Your contact Numbers
            </div>

            {mobNum &&
              mobNum?.map((m) => {
                return (
                  <div
                    className="mobileNum"
                    style={{
                      border: m.is_verified
                        ? "0.1em solid var(--iconCol)"
                        : "0.1em solid indianred",
                      background: darkTheme && "var(--darkBg1)",
                      color: darkTheme && "var(--darkText)",
                    }}
                  >
                    <span>{m.number}</span>
                    <span className="verifiedTag">
                      <span
                        className="pointer"
                        onClick={() => handleResendOtp(m.is_verified, m.number)}
                      >
                        {m.is_verified ? "verified" : "verify"}{" "}
                      </span>
                      {m.is_verified ? (
                        <i
                          class="fa-solid fa-circle-check"
                          style={{ color: "#67a97b" }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-circle-xmark"
                          style={{ color: "indianred" }}
                        ></i>
                      )}{" "}
                    </span>
                  </div>
                );
              })}
          </div>
        )}

        {addresses && addresses.length > 0 && (
          <div className="mobileNumTable address">
            <div style={{ color: darkTheme && "var(--darkText)" }}>
              Your {addresses.length===1?'address':'addresses'}
            </div>
            {addresses?.map((m) => {
              return (
                <div
                  className="mobileNum"
                  style={{
                    background: darkTheme && "var(--darkBg1)",
                    color: darkTheme && "var(--darkText)",
                  }}
                >
                  <div>
                    {` ${m.address.Locality}, ${m.address.Near_By}, ${m.address.District}, ${m.address.State}, ${m.address.Pincode} `}
                  </div>
                </div>
              );
            })}
          </div>
        )}


        {(mobNum.length===0 && addresses.length===0) && (
          <>
          <div className={darkTheme&&'dark'}>No mobile number and address exists</div>
          </>
        ) }
</>
}
      

        <Overlay state={[verifyDis, setVerifyDis]}>
          <form className="resendOTP" onSubmit={handleVerifyOtp}>
            <div className="title">Enter OTP sent to your contact number</div>
            <input type="number" name="otp" />
            <button>Submit</button>
          </form>
        </Overlay>

        <Overlay state={[addPinDis, setAddPinDis]}>
          {/* <form className="addPinCode" onSubmit={handleAddPinCode}>
            <div className="title">Please fill below details</div>
            <div className="flex">
         
            <select name="country" required>
              <option value="0" disabled selected>Select your country</option>
              {countryList.map((m)=>{
                return <option value={m.translate}>{m.translate}</option>
              })}
            </select>
            </div>
            <div className="flex">
            <select name="state" required onChange={getValuesOfAddPinCode}>
              <option value="0" disabled selected>Select your state</option>
              {stateList && Array.isArray(stateList)
                ? stateList.map((m) => (
                    <option key={m.id} value={m.translate}>
                      {m.translate}
                    </option>
                  ))
                : null}
            </select>
           
            </div>
            <div className="flex">
        <select name="region" required value={openValues.region} onChange={handleChange}>
          <option value="0" disabled>Select your region</option>
          {regionList.map((m) => (
            <option key={m.translate} value={m.translate}>
              {m.translate}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
        {openValues.region === 'Other' && (
          <input type="text" placeholder="Enter your region" name="regionInput" />
        )}
      </div>

      <div className="flex">
        <select name="division" required value={openValues.division} onChange={handleChange}>
          <option value="0" disabled>Select your division</option>
          {divisionList.map((m) => (
            <option key={m.translate} value={m.translate}>
              {m.translate}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
        {openValues.division === 'Other' && (
          <input type="text" placeholder="Enter your division" name="divisionInput" />
        )}
      </div>

      <div className="flex">
        <select name="district" required value={openValues.district} onChange={handleChange}>
          <option value="0" disabled>Select your district</option>
          {districtList.map((m) => (
            <option key={m.translate} value={m.translate}>
              {m.translate}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
        {openValues.district === 'Other' && (
          <input type="text" placeholder="Enter your district" name="districtInput" />
        )}
      </div>

      <div className="flex">
        <select name="block" required value={openValues.block} onChange={handleChange}>
          <option value="0" disabled>Select your block</option>
          {blockList.map((m) => (
            <option key={m.translate} value={m.translate}>
              {m.translate}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
        {openValues.block === 'Other' && (
          <input type="text" placeholder="Enter your block" name="blockInput" />
        )}
      </div>

      <div className="flex">
        <select name="area" required value={openValues.area} onChange={handleChange}>
          <option value="0" disabled>Select your area</option>
          {areaList.map((m) => (
            <option key={m.translate} value={m.translate}>
              {m.translate}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
        {openValues.area === 'Other' && (
          <input type="text" placeholder="Enter your area" name="areaInput" />
        )}
      </div>

      <div className="flex">
        <input type="text" placeholder="Enter your pincode" name="pincode" />
      </div>

      <br />
      <button type="submit">Submit</button>
          </form> */}
          <AddPincode display={setAddPinDis} />
        </Overlay>
      </div>
    </>
  );
}

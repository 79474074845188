import React, { useEffect, useState } from "react";
import ForgotImg from "../images/forgotPassPan.png";
import panLogo from "../images/panelLogo.png";
import myApi from "../components/MyApi";
import { showErrorCard } from "../App";
import { useNavigate } from "react-router-dom";

export default function ForgotPass() {
  const navigate = useNavigate()
  const [uuid, setUuid] = useState(null);
  const [emailToken, setEmailToken] = useState(null);
  useEffect(() => {
    const tokenUrl = window.location.href.split("/");
    setEmailToken(tokenUrl[tokenUrl.length - 1]);
    setUuid(tokenUrl[tokenUrl.length - 2]);
  }, []);

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      let postUrl = `/account/restPassword/${uuid}/${emailToken}`;
      // console.log(postUrl);
      await myApi.post(postUrl, {
        password: e.target.pass.value,
        password2: e.target.conPass.value,
      });
      showErrorCard("Password Successfully Changed", "success");
      setTimeout(
        () =>
          (window.location.href =
            window.location.href.split("/").slice(0, 3).join("/") + "/login"),
        3000
      );
    } catch (err) {
      console.log(err.response.data.errors);
      showErrorCard(err.response.data.errors, "error");
    }
  };
  return (
    <div className="forgotPan dfr">
      <div className="left dfr">
        <div className='dfc' style={{width:'13%',position:'absolute',top:'2%',left:'12%'}} onClick={()=>navigate('/')} >
          <img  src={panLogo} alt="" style={{maxWidth:'100%'}} />
          <div style={{ color: "var(--iconCol)", textWrap: "nowrap" }}>
            Every opinion matter
          </div>
        </div>
        <div className="forgotFieldCont dfc">
          <div>Forgot Password</div>
          <form action="" onSubmit={(e) => handleReset(e)}>
            <input
              type="password"
              placeholder="New Password"
              name="pass"
              required
            />
            <br />
            <input
              type="password"
              placeholder="Confirm New Password"
              name="conPass"
              required
            />
            <br />

            <button className="forgotBtn">Submit</button>
          </form>
        </div>
      </div>
      <div className="right dfc">
        <img src={ForgotImg} alt="" />
      </div>
    </div>
  );
}

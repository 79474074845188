import React,{useState,useEffect} from 'react'
import panLogo from "../images/panelLogo.png";
import flowRibbon from "../images/flowRib.png"
import panFlower from "../images/loader.png"
import myApi from './MyApi';
import Loader from './Loader';
import Logo from "./Logo";


export default function ErrorPage(props) {
  const [uuid,setUuid] = useState(null);
  const [emailToken,setEmailToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message,setMessage] = useState(props.text);

  useEffect(() => {
    
    const delay = 2000; 

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, []);
  const verifyEmail = async ()=>{
    // e.preventDefault();
    try{
      let getUrl = `/account/verify/${uuid}/${emailToken}`
      const apiResp = await myApi.get(getUrl);
      // console.log(apiResp)
      if(apiResp.data.success){
        setMessage(apiResp.data.success)
      }else{
        setMessage(apiResp.data.errors)
      }
     
      // setTimeout(()=>window.location.href = (window.location.href).split('/').slice(0, 3).join('/')+"/login",3000)

    }catch(err){
      console.log(err)
    
    }

  }

  useEffect(()=>{
   
    if(props.text === "email verification"){
      const tokenUrl = window.location.href.split("/");
      setEmailToken(tokenUrl[tokenUrl.length-1]);
      setUuid(tokenUrl[tokenUrl.length-2]);
      verifyEmail()
    }
  })

  

  return (
    isLoading?
    <Loader errorLoader={true}/>:

      <div className="panHome dfr">
        <div className="left">
          <Logo/>
          {/* <img className="panLogo" src={panLogo} alt="" /> */}
          <div className="ribbonCont">
          <img className="panRibbon" src={flowRibbon} alt="" />
        </div>
          
        </div>
        <div className="right">
          <div className="navItems dfr">
            <span>Home</span>
            <a href="/about">About</a>
            <a href="/login">Login</a>
          </div>
          <div className="welTag">Welcome to 5th Poll - Where Your Opinions Earn You Rewards!</div>
          <div style={props.text === "email verification"?{width:"85%"}:{}} className="join errMessage">{message}</div>
        </div>
      </div>
  )
}

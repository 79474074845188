import React, { useState,useEffect } from "react";
import panLogo from "../images/panelLogo.png";
import panLogin from "../images/panelLogin.png";
import { showErrorCard } from "../App";
import myApi from "./MyApi";
import { useNavigate, useParams } from "react-router-dom";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "./Loader";

import dayjs from 'dayjs';
import docFile from '../terms.docx'
import Logo from '../components/Logo'
import { currLang, currCountry } from './utils';



export default function Login(props) {
  const [showPass, setShowPass] = useState(false);
  const [showSignup, setShowSignUp] = useState(false);
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [dob, setDob] = useState(null);
  const [userDob, setUserDob] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [gender,setGender] = useState(null);
  const [country,setCountry] = useState(null);
  const [countryList,setCountryList] = useState([]);
  const [genderList,setGenderList] = useState([]);
  const [rememPass,setRememPass] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  // const [referCode,setReferCode] = useState('');
  const [date,setDate] = useState('');
  const [errText,setErrText] = useState('');
  const [regErr,setRegErr] = useState('');
  const {referralCode} = useParams();
  const [userCountry, setUserCountry] = useState('');



  // const handleDownload = () => {
  //   const link = document.createElement('a');
  //   link.href = docFile;
  //   link.download = '5th Poll Terms and Conditions.doc'; // The name for the downloaded file
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const navigate = useNavigate();
  async function getGenderAndCountryList(userLang,userCountry) {
    try {
      const genderResponse = await myApi.get(`/panel/Gender/${userCountry}?lang=${userLang}`);
      // console.log(genderResponse.data.option_list)
      console.log(genderResponse.data.result.option_List)
      setGenderList(genderResponse.data.result.option_List)
    } catch (error) {
      console.log('Gender List response failed');
    }
  
    try {
      const countryResponse = await myApi.get(`/location/CountryList?lang=${userLang}`);
      setCountryList(countryResponse.data.result)
      // console.log(countryResponse.data.result)
    } catch (error) {
      console.log('Error fetching country list');
    }
  }
    useEffect(() => {
        const fetchLanguageAndCountry = async () => {
            // Fetch and set the language asynchronously
            const language = await currLang();
            // setUserLang(language);

            // Fetch and set the country asynchronously
            const countryName = await currCountry();
            // setUserCountry(countryName);
            getGenderAndCountryList(language,countryName);
        };

        fetchLanguageAndCountry();

    }, []);
  useEffect(()=>{

    if(localStorage.getItem("rememberMe")){
      // console.log(localStorage.getItem("rememberMe"))
      document.querySelector(".inputField input").value = (localStorage.getItem("rememberMe"))
      document.querySelectorAll(".inputField input")[1].value = (localStorage.getItem("remPass"))
      setRememPass(true)
      setEmail(localStorage.getItem("rememberMe"))
      setPassword(localStorage.getItem("remPass"))
    }

   
  },[])

  useEffect(()=>{
    let referStr = window.location.href.split('/');
    if(referStr[3]==='signup') setShowSignUp(true)
    // referStr = referStr[4];
    // if (referStr===undefined) return;
    // setReferCode(referStr);
    // setShowSignUp(true)
  },[])
 
const changeDateFormat = (date)=>{
var dateObj = new Date(date);
var month = dateObj.getMonth() + 1; // Month is zero-based, so we add 1
var year = dateObj.getFullYear();
var day = dateObj.getDate();
const formattedDate = `${year}-${month}-${day}`;
return formattedDate;
}

//  const handleDateChange = async (date)=>{
//       // console.log(date)
//       await setDob(date);
//       await setUserDob(changeDateFormat(dob));
//   }
  

  const handleSignup = async (e) => {
    e.preventDefault();

  //  console.log(`/account/register/respondent?code=${referralCode}`)
  //  console.log(username,email,password,conPassword,gender,userDob,country);
    if (password !== conPassword) {
      showErrorCard("Password and Confirm Password are not same", "error");
      setRegErr("Password and Confirm Password are not same")
      return;
    }
    // let dateOfBirth = document.getElementById(":r0:")?.value;
    let dateOfBirth = dob;
    // console.log(dob)
    setIsLoading(true)
   await myApi
      .post(`/account/register/respondent?code=${referralCode}`,{ 
        "email": email.toLowerCase(),
        "name": username,
        "termAndCondition": "True",
        "gender":gender,
        "country":country,
        "dateOfBirth":dateOfBirth,
        "password": password,
        "password2": conPassword,
      }
      )

      .then((response) => {
        // Handle the successful response here
        // console.log("POST request successful:", response);
        setIsLoading(false)
        localStorage.setItem('username',username);
        localStorage.setItem('email',email.toLowerCase());
        showErrorCard(
          "Signup Success Please verify yourself by using link sent to your email",
          "success"
        );
        setTimeout(()=>{
          navigate('/welcome')
        },500) 
      })
      .catch((error) => {
        // Handle any errors that occurred during the POST request
        console.log(
          "Error making POST request:",error
          // error.response.data.errors.email[0]
        );
        setIsLoading(false)
        showErrorCard(error.response.data.errors, "error");
        setRegErr(error.response.data.errors)
      });


  };

 
  
  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true)
    myApi
      .post("/account/login", {
        email: email.toLowerCase(),
        password: password,
      })
      .then((response) => {
        // Handle the successful response here
        // console.log("POST request successful:", response.data);
        showErrorCard("Login Success", "success");
        const { access, refresh } = response.data;

        // Store the token in localStorage or in a cookie for future requests
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        // checkTokenExpiration(access);
        setIsLoading(true)
        if(rememPass) {
          localStorage.setItem("rememberMe",email)
          localStorage.setItem("remPass",password)
        }
        else {
          localStorage.removeItem("rememberMe")
          localStorage.removeItem("remPass")
        }
        setTimeout(
          () =>
            (window.location.href =
              window.location.href.split("/").slice(0, 3).join("/") +
              "/dashboard"),
          100
        );
      })
      .catch((error) => {
        // Handle any errors that occurred during the POST request
        console.error("Error making POST request:", error);
        showErrorCard(error.response.data.errors, "error");
        setErrText(error.response.data.errors)
      
      });
      setIsLoading(false)

  };
  return (
    <>
      <div className="panLoginCont">
      <div className="first dfr">
        <div className="left">
          {/* <div className="dfc" style={{width:'30%',marginTop:'2%',marginLeft:'2%'}}>
          <img className="panLogo" src={panLogo} alt="" onClick={()=>navigate("/")} />
          <div style={{color:'var(--iconCol)',textWrap:'nowrap'}}>Every opinion matter</div>
          </div> */}
          <Logo/>
         
          <div className="inputFieldCont">
            <div className="welTag">Welcome Back</div>
            <div className="lsDiv">
              <div
                style={
                  showSignup
                    ? { borderBottom: "0.2em solid var(--iconCol)" }
                    : {}
                }
                onClick={() => setShowSignUp(true)}
              >
                Sign Up
              </div>
              <div
                style={
                  !showSignup
                    ? { borderBottom: "0.2em solid var(--iconCol)" }
                    : {}
                }
                onClick={() => setShowSignUp(false)}
              >
                Login
              </div>
            </div>

            {showSignup ? (
              <form
                action=""
                onSubmit={(e) => handleSignup(e)}
                autoComplete="on"
                className="loginForm"
              >
                <div className="fieldsCont">
                  
                  <div className="leftFields">
                    <div className="inputField">
                      <i className="fa-regular fa-circle-user"></i>
                     
                      <input
                        type="text"
                        placeholder="Full Name"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="on"
                      />
                    </div>

                    <div className="inputField">
                      <i className="fa-regular fa-envelope"></i>
                      <input
                        type="email"
                        placeholder="Email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                      />
                    </div>

                    <div className="inputField">
                      <i className="fa-solid fa-lock"></i>
                      <input
                        type="password"
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>

                    <div className="inputField">
                      <i className="fa-solid fa-lock"></i>
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        required
                        onChange={(e) => setConPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="rightFields">
                    <div className="inputField">
                      <i className="fa-solid fa-cake-candles"></i>
                      {/* <DatePicker
                        placeholderText="Date of birth"
                        selected={dob}
                        onChange={(date) => handleDateChange(date)}
                        dateFormat="yyyy-MM-dd"
                        name='dob'
                        required
                      /> */}
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}  sx={{width:'100%'}}>
      <DatePicker
  label="Date of birth"
  format="YYYY-MM-DD"
  sx={{width:'100%'}}
/>
      </DemoContainer>
    </LocalizationProvider> */}
    <input type="date"  onChange={(e)=>setDob(e.target.value)} onClick={(e) => e.target.showPicker()}/>
                   
                   
                    </div>
                    <div className="inputField">
                      <i
                        style={{ marginRight: "1.5%" }}
                        className="fa-solid fa-person-half-dress"
                      ></i>
                      <select name="Gender" id="" required
                      onChange={(e)=>setGender(e.target.value)}>
                        <option value="" disabled selected hidden>Gender</option>
                        {/* <option value="1cff902f-0c2a-4a05-be80-2bf20ce1f646">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Trans</option> */}
                        {genderList?.map(e=><option value={e.translate}>{e.translate}</option>)}
                      </select>
                    </div>

                    <div className="inputField">
                      <i className="fa-solid fa-globe"></i>
                      <select name="" id="" required onChange={(e)=>setCountry(e.target.value)}>
                        <option value="" disabled selected hidden>Country</option>
                       {countryList?.map(cl=><option value={cl.translate}>{cl.translate}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  {/* <input style={{marginRight:'2%'}} type="checkbox" name="" id="" required /> */}
                  <span> By signing up, you are agreeing to our <a className='pointer' style={{color:'blue',textDecoration:'underline'}} onClick={()=>navigate('/termsAndConditions')}>Terms and conditions</a>.</span>
                </div>
                <button className="loginBtn signBtn">Sign Up</button>
                <div style={{marginTop:'2%',color:'indianred'}}>{regErr}</div>
              </form>
            ) : (
              <form onSubmit={handleLogin} autoComplete="on" className="loginForm">
               
                <div className="inputField">
                  <i className="fa-regular fa-circle-user"></i>
                  <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="inputField">
                  <i className="fa-solid fa-lock"></i>
                  <input
                    type={showPass ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    autoComplete="off"
                  />
                  <i
                    className={`fa-solid ${showPass ? 'fa-eye':'fa-eye-slash'}`}
                    onClick={() => setShowPass(!showPass)}
                  ></i>
                </div>
                <div className="remPassCont">
                  <div className="dfr">
                    <input type="checkbox" checked={rememPass} onChange={()=>setRememPass(!rememPass)} />
                    Remember Me
                  </div>
                  <a href="/resetpassword">Forgot Password</a>
                </div>
                <div style={{color:'indianred',marginTop:'2%'}}>{errText}</div>
                <button className="loginBtn">Login</button>
              </form>
            )}

            {/* <div className='signUpTag'><a href="/signup" >Don't Have an Account? Sign Up</a></div> */}
          </div>
        </div>
        <div className="right">
          <div className="text dfc">
            <div>BEST PLACE FOR INSPIRATION</div>
            <div className="cText" style={{textAlign:'center'}}>
            <div className="big">Creative</div>
            <div className="big">Community</div>
            </div>
         
            <div style={{ color: "black" }}>500K MEMBERS JOINED LAST YEAR</div>
          </div>
        </div>
      </div>
      <div className="second">
        <img src={panLogin} alt="" />
      </div>
    </div>
    {isLoading&&<Loader/>}
    </>
  
  );
}
